import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"
import _imports_0 from '@/assets/img/logo.png'


const _hoisted_1 = { class: "flex w-full flex-col flex-grow justify-center align-middle bg-gray-100" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("div", { class: "block w-full" }, [
      _createElementVNode("img", {
        src: _imports_0,
        alt: "logo",
        class: "mx-auto",
        style: {"max-width":"200px"}
      })
    ], -1)
  ])))
}