<template>
  <div class="flex w-full flex-col flex-grow justify-center align-middle bg-gray-100">
    <div class="block w-full">
      <img src="@/assets/img/logo.png" alt="logo" class="mx-auto" style="max-width: 200px;" />
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
export default defineComponent({
  name: 'HomeView'
})
</script>
